import React from 'react';
import {RadioButton} from 'primereact/radiobutton';
import {OptionDefinition_v2, OptionDefinitionJsonModel_v2} from 'two-core';

interface Props {
  key: string;
  selectedOption?: OptionDefinition_v2;
  disabled: boolean;
  visibleOptions: OptionDefinition_v2[];
}

export const RadioField = ({key, selectedOption, disabled, visibleOptions}: Props) => {
  return (
    <div key={key}>
      {visibleOptions.map(option => {
        return (
          <div className="p-field-radiobutton p-mt-1 p-mb-0" key={option.key}>
            <RadioButton
              inputId={option.key}
              value={option.key}
              name={option.label}
              id={option.key}
              checked={selectedOption?.key === option.key}
              disabled={disabled}
            />
            <label htmlFor={option.key} className="p-mr-1">
              {option.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};
