import {Dropdown} from 'primereact/dropdown';
import {InputText} from 'primereact/inputtext';
import React from 'react';
import {B2bIntegrationApiSettings} from 'two-core/src/b2b-integration';
import {B2bTokenType} from 'two-core';

const settingTypeOptions: B2bTokenType[] = ['api'];

interface Props {
  settings?: B2bIntegrationApiSettings;
  onSettingChange: (settingsPatch: Partial<B2bIntegrationApiSettings>) => void;
}
export const SettingsSection = ({settings, onSettingChange}: Props) => {
  return (
    <>
      <div className="p-field p-grid">
        <label className="p-col-2">settings type</label>
        <div className="p-col-10">
          <Dropdown
            value={settings?.type}
            options={settingTypeOptions}
            onChange={e => onSettingChange({type: e.value})}
          />
        </div>
      </div>
      {settings?.type === 'api' && (
        <>
          <div className="p-field p-grid">
            <label className="p-col-2">notification url</label>
            <div className="p-col-10">
              <InputText
                value={settings?.notification_url}
                onChange={e => onSettingChange({notification_url: e.target.value})}
              />
            </div>
          </div>
          <div className="p-field p-grid">
            <label className="p-col-2">authorised IPs</label>
            <div className="p-col-10">
              <InputText
                value={settings?.authorized_ips}
                onChange={e => onSettingChange({authorized_ips: e.target.value})}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
