import {faChevronDown, faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Panel, PanelHeaderTemplateOptions} from 'primereact/panel';
import React from 'react';
import {AppContext} from 'two-app-ui';
import {DocumentItem} from 'two-core';
import {DocumentItemPanelHeader} from './DocumentItemPanelHeader';
import {DocumentItemPanelContent} from './DocumentItemPanelContent';

export type DocumentItemFormMode = 'readonly' | 'simple_edit' | 'advanced_edit' | 'repair';

interface ReadOnlyProps {
  mode: 'readonly';
  item: DocumentItem;
}

type Props = ReadOnlyProps;

class OrderItemComponent extends React.PureComponent<Props, {}> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {item, mode} = this.props;

    return (
      <Panel
        headerTemplate={options => <DocumentItemPanelHeader options={options} item={item} mode={mode} />}
        className={'p-mb-2 order-item-panel p-shadow-3'}
      >
        <DocumentItemPanelContent mode={mode} item={item} />
      </Panel>
    );
  }
}

export default OrderItemComponent;
