import {ApiService, AuthService} from 'two-app-ui';
import {User, QueryParameter, ApiListResponse, ApiResponse, TwoType, B2bToken} from 'two-core';
import config from '../config/config';

export class AdminB2bTokenService extends ApiService {
  protected endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints?.b2bTokens as string;
  }

  async getTokens(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createToken(params: B2bToken): Promise<User> {
    return this.post(this.endpoint, params)
      .then((response: unknown) => {
        console.log(response);
        return Promise.resolve(response as User);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async saveToken(id: string, params: B2bToken): Promise<User> {
    return this.patch(this.endpoint + '/' + id, params)
      .then((response: TwoType) => {
        console.log(response);
        return Promise.resolve(response as User);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default AdminB2bTokenService;
