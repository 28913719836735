import React, {Component} from 'react';
import {Column} from 'primereact/column';
import {MenuItem, MenuItemOptions} from 'primereact/menuitem';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  TwoDataTable,
  TwoToast,
} from 'two-app-ui';
import {
  B2bIntegration,
  CompanyToB2bIntegration,
  CompanyToB2bIntegrationAggregate,
  QueryParameter,
  TwoType,
} from 'two-core';
import B2bIntegrationsService from '../../services/B2bIntegrationsService';
import {Dropdown} from 'primereact/dropdown';
import {faPlus, faTrashAlt} from '@fortawesome/pro-regular-svg-icons';
import AddCompanyTob2bIntegrationDialog from './AddCompanyTob2bIntegrationDialog';
import {confirmDialog} from 'primereact/confirmdialog';

interface Props {
  b2bIntegration: B2bIntegration;
}
interface State {
  loading: boolean;
  showAddDialog?: boolean;
  showRemoveDialog?: boolean;
  b2bIntegrationCompanies: CompanyToB2bIntegration[];
  selectedB2bIntegrationCompanies: CompanyToB2bIntegration[];
}

export default class B2bIntegrationCompaniesList extends Component<Props, State> {
  static contextType = AppContext;

  b2bIntegrationsService?: B2bIntegrationsService;
  twoToast?: TwoToast;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      b2bIntegrationCompanies: [],
      selectedB2bIntegrationCompanies: [],
    };
    this.loadData = this.loadData.bind(this);
    this.companyBody = this.companyBody.bind(this);
  }

  componentDidMount() {
    this.b2bIntegrationsService = this.context.b2bIntegrationsService;
    this.twoToast = this.context.twoToast;

    this.loadData();
  }

  async loadData() {
    const {b2bIntegration} = this.props;
    this.setState({loading: true});
    const b2bIntegrationCompanies = await this.loadB2bIntegrationCompanies(b2bIntegration.id!);
    this.setState({loading: false, b2bIntegrationCompanies, selectedB2bIntegrationCompanies: []});
  }

  async loadB2bIntegrationCompanies(b2bIntegrationId: number) {
    try {
      const orderBys = [JSON.stringify({field: 'company.name', direction: 'asc'})];
      const aggregate: CompanyToB2bIntegrationAggregate[] = ['company', 'default_contact'];
      const queryParams: QueryParameter = {orderBys, aggregate};
      const response = await this.b2bIntegrationsService?.getCompanyToB2bIntegrations(b2bIntegrationId, queryParams);
      const b2bIntegrationCompanies = (response?.records ?? []) as CompanyToB2bIntegration[];
      return b2bIntegrationCompanies;
    } catch (e) {
      console.error(e);
      this.twoToast?.showError("Error loading b2b integration's companies");
      return [];
    }
  }

  menuItems(isHeader?: boolean): MenuItem[] {
    const selectedItems = this.state.selectedB2bIntegrationCompanies ?? [];
    const menuItems: MenuItem[] = [];
    if (isHeader) {
      menuItems.push({
        label: 'Add',
        faIcon: faPlus,
        command: () => this.setState({showAddDialog: true}),
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      });
    }
    if (selectedItems.length > 0) {
      menuItems.push({
        label: 'Remove',
        faIcon: faTrashAlt,
        command: () => {
          let header = 'Remove Company';
          if (selectedItems.length > 1) {
            header = 'Remove Companies';
          }
          const message =
            'Please keep in mind, that removing a company from an integration means, this company will no longer be able to use said integration to submit orders or request any updates. Active requests will be finished and all logged requests will stay on the logs.';
          confirmDialog({
            message: message,
            header: header,
            accept: () => {
              this.onRemove(selectedItems);
            },
            acceptLabel: 'Remove',
            rejectLabel: 'Cancel',
          });
        },
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      });
    }
    return menuItems;
  }

  onRemove(selectedItems: CompanyToB2bIntegration[]) {
    const {b2bIntegration} = this.props;
    const promises = selectedItems.map(selectedItem => {
      return this.b2bIntegrationsService?.deleteCompanyToB2bIntegration(b2bIntegration.id!, selectedItem.company_id!);
    });
    Promise.all(promises)
      .then(() => {
        this.loadData();
        this.twoToast?.showSuccess('Companies removed from b2b integration');
      })
      .catch(e => {
        console.error(e);
        this.twoToast?.showError('Error removing companies from b2b integration');
      });
  }

  companyBody(rowData: CompanyToB2bIntegration) {
    const {selectedB2bIntegrationCompanies} = this.state;
    return (
      <AppColumnMenuBodyTemplate
        key={rowData.id}
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.menuItems()}
        selectedItems={selectedB2bIntegrationCompanies}
        handleChangeSelectedItems={async () => {
          if (!selectedB2bIntegrationCompanies.some(item => item.id === rowData.id)) {
            this.setState({
              selectedB2bIntegrationCompanies: [...selectedB2bIntegrationCompanies, rowData],
            });
          }
        }}
      >
        <span>
          {rowData.company?.name} ({rowData.company?.trading_as})
        </span>
      </AppColumnMenuBodyTemplate>
    );
  }

  defaultContactBody(rowData: CompanyToB2bIntegration) {
    return (
      <span>
        {rowData.default_contact?.first_name} {rowData.default_contact?.last_name}
      </span>
    );
  }

  render() {
    const {b2bIntegration} = this.props;
    const {loading, selectedB2bIntegrationCompanies, b2bIntegrationCompanies, showRemoveDialog, showAddDialog} =
      this.state;
    return (
      <div className="b2b-integrations-table">
        <TwoDataTable
          loading={loading}
          value={b2bIntegrationCompanies}
          activeFilters={{}}
          selectedItems={selectedB2bIntegrationCompanies}
          initMenuItems={() => this.menuItems(true)}
          showPaging={false}
          selectionMode={'multiple'}
          handleChangeSelectedItems={items =>
            this.setState({selectedB2bIntegrationCompanies: items as CompanyToB2bIntegration[]})
          }
        >
          <Column header="Company" body={this.companyBody} />
          <Column header="Account Number" field="company.account_number" />
          <Column header="Default Contact" body={this.defaultContactBody} />
        </TwoDataTable>
        <AddCompanyTob2bIntegrationDialog
          b2bIntegration={b2bIntegration}
          showDialog={showAddDialog ?? false}
          onHide={() => this.setState({showAddDialog: false}, () => this.loadData())}
          assignedCompanyIds={b2bIntegrationCompanies.map(company => company.company_id!)}
        />
      </div>
    );
  }
}
