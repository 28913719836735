import React from 'react';
import {FileUpload} from 'primereact/fileupload';

interface Props {
  key: string;
  value?: string;
  disabled: boolean;
}
export const UploadField = ({key, value, disabled}: Props) => {
  return (
    <FileUpload
      chooseOptions={{
        icon: 'pi pi-upload',
        className: 'p-button-outlined',
      }}
      name={key}
      mode="basic"
      auto={true}
      disabled={disabled}
    />
  );
};
