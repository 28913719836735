import React from 'react';
import {FieldDefinition, Product} from 'two-core';
import {ProductDefinition} from 'two-core';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {Tree} from 'primereact/tree';
import TreeNode from 'primereact/treenode';
import ProductsService from '../../services/ProductsService';
import {AppContext} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import {ProductDefinitionRevision} from 'two-core/build/cjs/src/product-definition';
import {ProgressSpinner} from 'primereact/progressspinner';
import ProductDefinitionsService from '../../services/ProductDefinitionsService';

interface Props {
  revision: ProductDefinitionRevision;
  toast: React.RefObject<Toast>;
}

interface State {
  selectedProduct?: Product;
  products?: Product[];
  productDefinitions?: ProductDefinition[];
  loading: boolean;
}

class RevisionProducts extends React.Component<Props, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;
  productsService: ProductsService | null = null;
  productDefinitionsService: ProductDefinitionsService | null = null;
  constructor(props: Props) {
    super(props);
    this.state = {
      products: [],
      productDefinitions: [],
      loading: true,
    };

    this.toast = React.createRef();
    this.onProductChange = this.onProductChange.bind(this);
    this.loadProductDefinitions = this.loadProductDefinitions.bind(this);
    this.loadProducts = this.loadProducts.bind(this);
  }

  componentDidMount() {
    this.productsService = this.context.productsService;
    this.productDefinitionsService = this.context.productDefinitionsService;

    const revisionId = this.props.revision.id;
    if (revisionId) {
      this.loadProductDefinitions(revisionId);
    }
  }

  loadProductDefinitions(revisionId: number) {
    const productDefinitionFilters: string[] = [];
    const productDefinitionParams = {
      aggregate: true,
      filters: productDefinitionFilters,
    };

    productDefinitionParams.filters.push(
      JSON.stringify({
        field: 'revision_id',
        value: revisionId,
      })
    );

    this.productDefinitionsService?.getProductDefinitions(productDefinitionParams).then(productDefinitionsResponse => {
      const productDefinitions: ProductDefinition[] =
        productDefinitionsResponse.records as unknown as ProductDefinition[];
      if (!productDefinitions) {
        throw new Error('Api Error, no product definition returned');
      }
      this.loadProducts();
      this.setState({
        productDefinitions: productDefinitions,
      });
    });
  }

  loadProducts() {
    this.productsService?.getProducts({aggregate: true}).then(productsResponse => {
      const products: Product[] = productsResponse.records as Product[];
      if (!products) {
        throw new Error('Api Error, no products returned');
      }
      this.setState({
        products: products,
        selectedProduct: products[0],
      });
    });
  }

  onProductChange(e: DropdownChangeParams) {
    this.setState({selectedProduct: e.value});
  }

  getProductDefinitionTree(productDefinition: ProductDefinition | undefined): TreeNode[] {
    if (!productDefinition) {
      return [];
    }
    return productDefinition.field_definitions.map(field => this.getFieldDefinitionTreeNode(field as FieldDefinition));
  }

  getFieldDefinitionTreeNode(fieldDefinition: FieldDefinition): TreeNode {
    const treeNode: TreeNode = {
      key: fieldDefinition.id,
      label: fieldDefinition.name,
      data: fieldDefinition,
    };
    if (fieldDefinition.sub_fields) {
      treeNode.children = fieldDefinition.sub_fields.map(subField =>
        this.getFieldDefinitionTreeNode(subField as FieldDefinition)
      );
    }

    return treeNode;
  }

  render() {
    const {products, productDefinitions, selectedProduct} = this.state;
    const selectedProductDefinition = productDefinitions?.find(
      productDefinition => productDefinition.product_id === selectedProduct?.id
    );

    const productDefinitionTreeNode: TreeNode[] = this.getProductDefinitionTree(selectedProductDefinition);

    return products ? (
      <div className={'p-m-2'}>
        <Dropdown
          name="product"
          id="product"
          value={selectedProduct}
          options={products}
          onChange={this.onProductChange}
          optionLabel="name"
        />
        <Tree className={'p-mt-2'} value={productDefinitionTreeNode} />
      </div>
    ) : (
      <ProgressSpinner />
    );
  }
}

export default RevisionProducts;
