import {faBuilding, faCheck, faList, faPencil, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {ProgressSpinner} from 'primereact/progressspinner';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {AppContext, TwoAction, TwoEntityComponent, TwoEntityPanel, TwoToast} from 'two-app-ui';
import {B2bIntegration, QueryParameter} from 'two-core';
import B2bIntegrationsService from '../../services/B2bIntegrationsService';
import {B2bIntegrationDetail} from './B2bIntegrationDetail';
import EditB2bIntegrationDialog from './EditB2bIntegrationDialog/EditB2bIntegrationDialog';
import B2bIntegrationCompaniesList from './B2bIntegrationCompaniesList';
import B2bRequestList from '../B2bRequest/B2bRequestList';

interface RouteProps {
  id: string;
}

interface State {
  b2bIntegration?: B2bIntegration;
  loading?: boolean;
  saving?: boolean;
  showEditDialog?: boolean;
}

class B2bIntegrationPage extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;

  b2bIntegrationsService?: B2bIntegrationsService;
  twoToast?: TwoToast;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.onHideEditDialog = this.onHideEditDialog.bind(this);

    this.state = {};
  }

  componentDidMount() {
    this.b2bIntegrationsService = this.context.b2bIntegrationsService;
    this.twoToast = this.context.twoToast;

    this.loadData();
  }

  async loadData() {
    this.setState({loading: true});
    const b2bIntegrationId = parseInt(this.props.match.params.id);
    const b2bIntegration = await this.loadB2bIntegration(b2bIntegrationId);
    if (!b2bIntegration) {
      this.setState({loading: false});
      return;
    }
    this.setState({loading: false, saving: false, b2bIntegration});
  }

  async loadB2bIntegration(b2bIntegrationId: number) {
    try {
      const filters = [JSON.stringify({field: 'id', value: b2bIntegrationId})];
      const queryParams: QueryParameter = {filters};
      const response = await this.b2bIntegrationsService?.getB2bIntegrations(queryParams);
      const b2bIntegrations = (response?.records ?? []) as B2bIntegration[];
      return b2bIntegrations?.[0];
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading b2b integration');
      return undefined;
    }
  }

  async updateB2bIntegration(b2bIntegrationId: number, b2bIntegrationPatch: Partial<B2bIntegration>) {
    this.setState({saving: true});
    try {
      await this.b2bIntegrationsService?.updateB2bIntegration(b2bIntegrationId, b2bIntegrationPatch);
      this.twoToast?.showSuccess('B2b integration updated');
      this.loadData();
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error updating b2b integration');
      this.setState({saving: false});
    }
  }

  getActions(): TwoAction[] {
    const {b2bIntegration} = this.state;
    const actions: TwoAction[] = [];
    actions.push({
      icon: faPencil,
      label: 'Edit',
      main: true,
      action: () => {
        this.setState({showEditDialog: true});
      },
    });
    if (b2bIntegration?.enabled) {
      //disable action
      actions.push({
        icon: faXmark,
        label: 'Disable',
        action: () => {
          this.updateB2bIntegration(b2bIntegration.id!, {enabled: false});
        },
      });
    } else {
      //enable action
      actions.push({
        icon: faCheck,
        label: 'Enable',
        action: () => {
          this.updateB2bIntegration(b2bIntegration!.id!, {enabled: true});
        },
      });
    }
    return actions;
  }
  onHideEditDialog() {
    this.setState({showEditDialog: false});
    this.loadData();
  }
  render() {
    const {b2bIntegration, showEditDialog, loading, saving} = this.state;

    if (loading || saving) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }

    if (!b2bIntegration) {
      return <></>;
    }

    return (
      <>
        <TwoEntityComponent title={b2bIntegration.name} actions={this.getActions()}>
          <TwoEntityPanel isPrimary={true}>
            <B2bIntegrationDetail b2bIntegration={b2bIntegration} />
          </TwoEntityPanel>
          <TwoEntityPanel icon={faBuilding}>
            {b2bIntegration ? <B2bIntegrationCompaniesList b2bIntegration={b2bIntegration} /> : <></>}
          </TwoEntityPanel>
          <TwoEntityPanel icon={faList}>
            {b2bIntegration ? <B2bRequestList b2bIntegrationId={b2bIntegration.id} /> : <></>}
          </TwoEntityPanel>
        </TwoEntityComponent>
        <EditB2bIntegrationDialog
          showDialog={showEditDialog ?? false}
          onHide={() => this.setState({showEditDialog: false}, () => this.loadData())}
          b2bIntegration={b2bIntegration}
        />
      </>
    );
  }
}

export default withRouter(B2bIntegrationPage);
