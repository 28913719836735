export const config = () => {
  const configService = process.env.REACT_APP_CONFIG_SERVICE;
  const productService = process.env.REACT_APP_PRODUCT_SERVICE;
  const tleService = process.env.REACT_APP_TLE_SERVICE;
  const companyService = process.env.REACT_APP_COMPANY_SERVICE;
  const invoiceService = process.env.REACT_APP_INVOICE_SERVICE;
  const orderService = process.env.REACT_APP_ORDER_SERVICE;
  const integrationService = process.env.REACT_APP_INTEGRATION_SERVICE;
  return {
    system: {
      stop_typing_detection: 1000,
      max_permissions_text_lenght: 10,
    },
    endpoints: {
      applications: configService + 'applications',
      companies: companyService + 'companies',
      roles: configService + 'roles',
      users: configService + 'users',
      products: productService + 'products',
      productGroups: productService + 'product-groups',
      productDefinitions: productService + 'product-definitions',
      productsDefinitionRevisions: productService + 'product-definition-revisions',
      tles: tleService + 'tles',
      priceLists: invoiceService + 'price-lists',
      priceDefinitionRevisions: invoiceService + 'price-definition-revisions',
      priceListVersions: invoiceService + 'price-list-versions',
      productPriceDefinitions: invoiceService + 'product-price-definitions',
      priceListProductCostings: invoiceService + 'price-list-product-costings',
      orders: orderService + 'orders',
      skuDepartment: invoiceService + 'sku-departments',
      b2bTokens: companyService + 'b2b-token',
      contacts: companyService + 'contacts',
      b2bIntegrations: integrationService + 'b2b-integrations',
      b2bRequests: integrationService + 'b2b-requests',
    },
  };
};
export default config;
