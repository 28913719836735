import React from 'react';
import {OptionDefinition_v2} from 'two-core';
import {MultiSelect} from 'primereact/multiselect';

interface Props {
  key: string;
  selectedOptions?: OptionDefinition_v2[];
  disabled: boolean;
  visibleOptions: OptionDefinition_v2[];
}

export const SelectMultiField = ({key, selectedOptions, disabled, visibleOptions}: Props) => {
  const tooltipValue = visibleOptions?.map(option => option.label).join(', ') ?? '';

  return (
    <MultiSelect
      name={key}
      id={key}
      value={selectedOptions}
      options={visibleOptions}
      disabled={disabled}
      tooltip={tooltipValue}
      tooltipOptions={{
        showOnDisabled: true,
        showDelay: 400,
        position: 'top',
      }}
      filter={visibleOptions.length > 7}
    />
  );
};
