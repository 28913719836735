import React from 'react';
import {InputText} from 'primereact/inputtext';
import {InputNumber} from 'primereact/inputnumber';

interface Props {
  key: string;
  value?: number;
  disabled: boolean;
  unit?: string;
}
export const NumberField = ({key, value, disabled, unit}: Props) => {
  return (
    <div className="p-inputgroup">
      <InputNumber
        name={key}
        id={key}
        value={value}
        disabled={disabled}
        tooltip={`${value}`}
        mode="decimal"
        inputMode="decimal"
        minFractionDigits={0}
        maxFractionDigits={4}
        tooltipOptions={{
          showDelay: 400,
          position: 'top',
        }}
        min={0}
        locale="en-AU"
      />
      {!!unit && <span className="p-inputgroup-addon">{unit}</span>}
    </div>
  );
};
