import React from 'react';

interface Props {
  key: string;
  values?: number[];
  disabled: boolean;
  unit?: string;
}
export const NumberMultiField = ({key, values, disabled, unit}: Props) => {
  return (
    <div key={key} className="p-d-flex p-jc-between">
      {values?.length && (
        <span className="p-mr-2 p-as-center">
          {values
            .map(value => {
              return `${value}${unit ?? ''}`;
            })
            .join(', ')}
        </span>
      )}
    </div>
  );
};
