import React from 'react';
import {FieldValue_v2} from 'two-core';
import {ReadOnlyFieldGroup} from './ReadOnlyFieldGroup';

interface Props {
  key: string;
  values?: FieldValue_v2[];
  disabled: boolean;
  unit?: string;
}
export const MixedField = ({key, values, disabled}: Props) => {
  return (
    <div key={key}>
      {values?.length && (
        <span className="p-mr-2 p-as-center">
          {values.map((value, index) => {
            if (value.subFields) {
              return <ReadOnlyFieldGroup key={index} subFields={value.subFields} index={index} />;
            } else {
              return <>No subfields</>;
            }
          })}
        </span>
      )}
    </div>
  );
};
