import {DocumentItem, Field_v2} from 'two-core';
import React from 'react';
import {DocumentItemFormMode} from './DocumentItemForm';
import {FieldFactory} from './Fields/FieldFactory';

interface Props {
  mode: DocumentItemFormMode;
  item: DocumentItem;
}
export const DocumentItemPanelContent = ({mode, item}: Props) => {
  const getColumns = (fields: Field_v2[]) => {
    const fieldColumns: {[key: number]: Field_v2[]} = {0: [], 1: [], 2: [], 3: []};
    for (const field of fields) {
      const fieldColumn = field.layoutInformation?.col ?? 0;
      fieldColumns[fieldColumn].push(field);
    }
    for (const fieldColumn of Object.values(fieldColumns)) {
      fieldColumn.sort((a, b) => (a.layoutInformation?.row ?? 0) - (b.layoutInformation?.row ?? 0));
    }
    return fieldColumns;
  };

  return (
    <div className="p-fluid p-formgrid p-grid">
      {Object.entries(getColumns(item.fields)).map(([columnIndex, columnFields]) => {
        return (
          <div key={columnIndex} className="p-col-3">
            {columnFields.map(field => {
              return <FieldFactory key={field.key} mode={mode} field={field} />;
            })}
          </div>
        );
      })}
    </div>
  );
};
