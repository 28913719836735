import React from 'react';
import {DocumentItemFormMode} from '../DocumentItemForm';
import {Field_v2, OptionDefinition_v2} from 'two-core';
import {TextField} from './TextField';
import {RadioField} from './RadioField';
import {NumberField} from './NumberField';
import {SelectField} from './SelectField';
import {NumberMultiField} from './NumberMultiField';
import {TextAreaField} from './TextAreaField';
import {SelectMultiField} from './SelectMultiField';
import {UploadField} from './UploadField';
import {MixedField} from './MixedFieldComponent/MixedField';
import {FieldContainer} from './FieldContainer';
import {CheckboxField} from './CheckboxField';

interface Props {
  mode: DocumentItemFormMode;
  field: Field_v2;
}
export const FieldFactory = ({mode, field}: Props) => {
  let disabled = false;
  const label = field.label ?? 'unknown';
  const key = field.key;
  const visibleOptions: OptionDefinition_v2[] = [];
  if (mode === 'readonly') {
    disabled = true;
    if (field.type === 'radio' || field.type === 'select' || field.type === 'select_buttons_multi') {
      for (const value of field.values ?? []) {
        if (value.key && value.value) {
          visibleOptions.push(
            new OptionDefinition_v2({
              key: value.key ?? 'Unknown option',
              label: String(value.value) ?? 'Unknown option',
              data_value: value.dataValue,
            })
          );
        } else {
          console.error(`Invalid option definition for field ${field.key}`, field);
        }
      }
    }
  }

  if (field.type === 'text') {
    return (
      <FieldContainer key={key} label={label}>
        <TextField value={field.getStringValue()} key={key} disabled={disabled} />
      </FieldContainer>
    );
  }

  if (field.type === 'textarea') {
    return (
      <FieldContainer key={key} label={label}>
        <TextAreaField value={field.getStringValue()} key={key} disabled={disabled} />
      </FieldContainer>
    );
  }
  if (field.type === 'number') {
    return (
      <FieldContainer key={key} label={label}>
        <NumberField value={field.getNumberValue()} key={key} disabled={disabled} unit={field.unit} />
      </FieldContainer>
    );
  }
  if (field.type === 'number_multi') {
    return (
      <FieldContainer key={key} label={label}>
        <NumberMultiField values={field.getNumberValuesList()} key={key} disabled={disabled} unit={field.unit} />
      </FieldContainer>
    );
  }
  if (field.type === 'radio') {
    const selectedOption = visibleOptions.find(option => option.key === field.values[0].key);
    return (
      <FieldContainer key={key} label={label}>
        <RadioField key={key} disabled={disabled} visibleOptions={visibleOptions} selectedOption={selectedOption} />
      </FieldContainer>
    );
  }
  if (field.type === 'select') {
    const selectedOption = visibleOptions.find(option => option.key === field.values[0].key);
    return (
      <FieldContainer key={key} label={label}>
        <SelectField key={key} disabled={disabled} visibleOptions={visibleOptions} selectedOption={selectedOption} />
      </FieldContainer>
    );
  }
  if (field.type === 'checkbox') {
    const selectedOptionKeys = field.values.map(value => value.key);
    const selectedOptions = visibleOptions.filter(option => selectedOptionKeys.includes(option.key));
    return (
      <FieldContainer key={key} label={label}>
        <CheckboxField
          key={key}
          disabled={disabled}
          visibleOptions={visibleOptions}
          selectedOptions={selectedOptions}
        />
      </FieldContainer>
    );
  }
  if (field.type === 'select_buttons_multi') {
    const selectedOptionKeys = field.values.map(value => value.key);
    const selectedOptions = visibleOptions.filter(option => selectedOptionKeys.includes(option.key));
    return (
      <FieldContainer key={key} label={label}>
        <SelectMultiField
          key={key}
          disabled={disabled}
          visibleOptions={visibleOptions}
          selectedOptions={selectedOptions}
        />
      </FieldContainer>
    );
  }
  if (field.type === 'mixed' || field.type === 'mixed_multi') {
    return (
      <FieldContainer key={key} label={label}>
        <MixedField key={key} values={field.values} disabled={disabled} />
      </FieldContainer>
    );
  }
  if (field.type === 'file_multi') {
    return (
      <FieldContainer key={key} label={label}>
        <UploadField key={key} disabled={disabled} />
      </FieldContainer>
    );
  }
  return (
    <div>
      Component: {field.label} of type: {field.type} not implemented
    </div>
  );
};
