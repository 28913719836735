import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, B2bIntegration, CompanyToB2bIntegration, QueryParameter} from 'two-core';

class B2bIntegrationsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.b2bIntegrations ?? '';
  }

  async getB2bIntegrations(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async createB2bIntegration(data: Partial<B2bIntegration>) {
    return this.post(this.endpoint, data)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async updateB2bIntegration(id: number, data: Partial<B2bIntegration>) {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  //add functions for find/add/delete company to b2b integration
  async getCompanyToB2bIntegrations(b2bIntegrationId: number, params: QueryParameter): Promise<ApiListResponse> {
    return this.get(`${this.endpoint}/${b2bIntegrationId}/companies`, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async createCompanyToB2bIntegration(b2bIntegrationId: number, data: Partial<CompanyToB2bIntegration>) {
    return this.post(`${this.endpoint}/${b2bIntegrationId}/companies`, data)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteCompanyToB2bIntegration(b2bIntegrationId: number, companyId: string) {
    return this.delete(`${this.endpoint}/${b2bIntegrationId}/companies/${companyId}`)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default B2bIntegrationsService;
