import React from 'react';
import {InputText} from 'primereact/inputtext';

interface Props {
  key: string;
  value?: string;
  disabled: boolean;
}
export const TextField = ({key, value, disabled}: Props) => {
  return (
    <InputText
      name={key}
      id={key}
      value={value}
      disabled={disabled}
      tooltip={value}
      tooltipOptions={{
        showOnDisabled: true,
        showDelay: 400,
        position: 'top',
      }}
    />
  );
};
