import React from 'react';
import {EntityDetailDivider, EntityDetailField} from 'two-app-ui';
import {B2bApiToken, B2bEmailToken, B2bIntegration, B2bIntegrationApiSettings} from 'two-core';
import formats from '../../config/formats';
import {DateTime} from 'luxon';
interface Props {
  b2bIntegration: B2bIntegration;
}
export const B2bIntegrationDetail = ({b2bIntegration}: Props) => {
  const renderToken = (token: B2bApiToken | B2bEmailToken) => {
    if (token.type === 'api') {
      return (
        <>
          <EntityDetailDivider />
          <EntityDetailField label="token type">{token.type}</EntityDetailField>
          <EntityDetailField label="access token">{token.access_token}</EntityDetailField>
          <EntityDetailField label="expires">
            {token.access_token_expires
              ? DateTime.fromISO(token.access_token_expires?.toString()).toFormat(formats.dateTime)
              : ''}
          </EntityDetailField>
          <EntityDetailField label="refresh token">{token.refresh_token}</EntityDetailField>
          <EntityDetailField label="expires">
            {token.refresh_token_expires
              ? DateTime.fromISO(token.refresh_token_expires?.toString()).toFormat(formats.dateTime)
              : ''}
          </EntityDetailField>
          <EntityDetailField label="scope">{token.scope}</EntityDetailField>
        </>
      );
    }
    if (token.type === 'email') {
      return (
        <>
          <EntityDetailDivider />
          <EntityDetailField label="token type">{token.type}</EntityDetailField>
          <EntityDetailField label="email from">{token.email_from}</EntityDetailField>
          <EntityDetailField label="email from">{token.email_to}</EntityDetailField>
        </>
      );
    }
    return <></>;
  };

  const renderSettings = (settings: B2bIntegrationApiSettings) => {
    if (settings.type === 'api') {
      return (
        <>
          <EntityDetailDivider />
          <EntityDetailField label="type">{settings.type}</EntityDetailField>
          <EntityDetailField label="notification url">{settings.notification_url}</EntityDetailField>
          <EntityDetailField label="authorized IPs">{settings.authorized_ips}</EntityDetailField>
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <EntityDetailField label="enabled">{b2bIntegration.enabled ? 'Enabled' : 'Disabled'}</EntityDetailField>
      <EntityDetailField label="manager">{b2bIntegration.manager_key}</EntityDetailField>
      {renderToken(b2bIntegration.token)}
      {renderSettings(b2bIntegration.settings)}
    </>
  );
};
