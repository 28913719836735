import {Button} from 'primereact/button';
import React from 'react';
import {AppContext, TwoDialog, TwoToast} from 'two-app-ui';
import {B2bIntegration, Company, CompanyAggregate, CompanyToB2bIntegration, Contact, QueryParameter} from 'two-core';
import B2bIntegrationsService from '../../services/B2bIntegrationsService';
import {Dropdown} from 'primereact/dropdown';
import CompaniesService from '../../services/CompaniesService';
import values from '../../config/values';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  b2bIntegration: B2bIntegration;
  assignedCompanyIds: string[];
}

interface State {
  loading?: boolean;
  lazyLoading?: boolean;
  saving?: boolean;
  companies?: Company[];
  selectedCompany?: Company;
  selectedContact?: Contact;
  nameFilter?: string;
  offset: number;
  pageSize: number;
}
export default class AddCompanyTob2bIntegrationDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  companiesService?: CompaniesService;
  b2bIntegrationsService?: B2bIntegrationsService;
  twoToast?: TwoToast;
  typingTimer?: NodeJS.Timeout;
  constructor(props: Props) {
    super(props);

    this.onHide = this.onHide.bind(this);
    this.onAdd = this.onAdd.bind(this);

    this.state = {companies: [], offset: 0, pageSize: 50};
  }

  componentDidMount() {
    this.companiesService = this.context.companiesService;
    this.b2bIntegrationsService = this.context.b2bIntegrationsService;
    this.twoToast = this.context.twoToast;
  }

  async loadData() {
    const {assignedCompanyIds} = this.props;
    const {nameFilter} = this.state;
    this.setState({loading: true});
    const companies = await this.loadCompanies(0, 50, assignedCompanyIds, nameFilter);
    this.setState({loading: false, companies, offset: 0});
  }

  async loadCompanies(offset: number, pageSize: number, assignedCompanyIds: string[], nameFilter?: string) {
    try {
      const filters = [];
      if (assignedCompanyIds.length) {
        filters.push(JSON.stringify({field: 'id', value: assignedCompanyIds, condition: 'notIn'}));
      }
      if (nameFilter?.length) {
        filters.push(JSON.stringify({field: 'name', value: nameFilter, condition: 'iLike'}));
      }
      const aggregate: CompanyAggregate[] = ['contacts'];
      const orderBys = [JSON.stringify({field: 'name', direction: 'asc'})];
      const params: QueryParameter = {offset, page_size: pageSize, orderBys, filters, aggregate};
      const response = await this.companiesService?.getCompanies(params);
      return (response?.records ?? []) as Company[];
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading companies');
      return [];
    }
  }

  async createCompanyTob2bIntegration(b2bIntegrationId: number, b2bIntegrationPatch: Partial<CompanyToB2bIntegration>) {
    this.setState({saving: true});
    try {
      await this.b2bIntegrationsService?.createCompanyToB2bIntegration(b2bIntegrationId, b2bIntegrationPatch);
      this.twoToast?.showSuccess('Company assigned to b2b integration');
      this.onHide();
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error assigning b2b integration');
      this.setState({saving: false});
    }
  }

  onHide() {
    this.setState({
      saving: false,
      companies: [],
      selectedCompany: undefined,
      selectedContact: undefined,
      nameFilter: undefined,
      offset: 0,
      pageSize: 50,
    });
    this.props.onHide();
  }

  onAdd() {
    const {b2bIntegration} = this.props;
    const {selectedCompany, selectedContact} = this.state;
    if (!selectedCompany) {
      this.twoToast?.showError('Please select a company');
      return;
    }
    if (!selectedContact) {
      this.twoToast?.showError('Please select a contact');
      return;
    }
    this.createCompanyTob2bIntegration(b2bIntegration.id!, {
      company_id: selectedCompany.id,
      default_contact_id: selectedContact.id,
    });
  }

  async onCompaniesLazyLoad(lastVisibleItemIndex: number) {
    const {assignedCompanyIds} = this.props;
    const {nameFilter, offset, pageSize, companies} = this.state;
    if (lastVisibleItemIndex >= offset + pageSize) {
      this.setState({lazyLoading: true});
      const loadedCompanies = await this.loadCompanies(offset + pageSize, pageSize, assignedCompanyIds, nameFilter);

      this.setState({
        companies: [...(companies ?? []), ...loadedCompanies],
        offset: offset + pageSize,
        lazyLoading: false,
      });
    }
  }

  async onNameFilterChange(value: string) {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(async () => {
      this.setState({lazyLoading: true});
      const companies = await this.loadCompanies(0, 50, this.props.assignedCompanyIds, value);
      this.setState({
        nameFilter: value,
        offset: 0,
        companies,
        selectedCompany: undefined,
        selectedContact: undefined,
        lazyLoading: false,
      });
    }, values.stopTypingDetection);
  }
  render() {
    const {showDialog, b2bIntegration} = this.props;
    const {saving, loading, lazyLoading, selectedCompany, selectedContact, companies} = this.state;

    const footer = (
      <div className={'p-d-flex p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={this.onHide} disabled={saving} />
        <Button label="Add" onClick={this.onAdd} loading={saving} disabled={saving} />
      </div>
    );

    const header = 'Add Company to B2b Integration';
    const content = (
      <div className="p-fluid w-100">
        <div className="p-field p-grid">
          <label className="p-col-2">Company</label>
          <div className="p-col-10">
            <Dropdown
              value={selectedCompany}
              options={companies}
              onChange={e => this.setState({selectedCompany: e.value, selectedContact: undefined})}
              virtualScrollerOptions={{
                lazy: true,
                onLazyLoad: e => this.onCompaniesLazyLoad(e.last as number),
                showLoader: true,
                loading: lazyLoading,
                itemSize: 45,
              }}
              optionLabel="name"
              filter
              onFilter={e => this.onNameFilterChange(e.filter)}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-2">Default Contact</label>
          <div className="p-col-10">
            <Dropdown
              value={selectedContact}
              options={selectedCompany?.contacts ?? []}
              onChange={e => this.setState({selectedContact: e.value})}
              itemTemplate={(contact: Contact) => `${contact.first_name ?? ''} ${contact.last_name ?? ''}`}
              valueTemplate={(contact: Contact) =>
                contact ? `${contact?.first_name ?? ''} ${contact?.last_name ?? ''}` : 'empty'
              }
              optionLabel="last_name"
            />
          </div>
        </div>
      </div>
    );

    return (
      <>
        <TwoDialog
          onHide={this.onHide}
          header={header}
          onShow={() => this.loadData()}
          loading={loading}
          showDialog={showDialog}
          style={{width: '50vw'}}
          breakpoints={{'768px': '60vw', '576px': '90vw'}}
          draggable={false}
          footer={footer}
        >
          {content}
        </TwoDialog>
      </>
    );
  }
}
