import React from 'react';
import {Tooltip} from 'primereact/tooltip';
import {Field_v2} from 'two-core';

interface Props {
  subFields: Field_v2[];
  index: number;
}
export const ReadOnlyFieldGroup = ({subFields, index}: Props) => {
  return (
    <div className="p-grid p-mt-1">
      <div className="p-col-8">
        {subFields.map((subField, subfieldIndex) => {
          const valueLabels: string[] = subField.values?.map(value => `${value.value}${subField.unit ?? ''}`) ?? [];
          return valueLabels.length ? (
            <div key={subField.key}>
              <label>{subField.label}:</label>
              <span id={`subfield-value-${index}-${subfieldIndex}`}>{valueLabels.map(label => label).join(', ')}</span>
            </div>
          ) : (
            <></>
          );
        })}
      </div>
    </div>
  );
};
