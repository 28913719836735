import React from 'react';
import {InputText} from 'primereact/inputtext';

interface Props {
  label: string;
  key: string;
}
export const FieldContainer = ({key, label, children}: React.PropsWithChildren<Props>) => {
  return (
    <div className="p-field p-grid p-mx-0 p-mb-2" key={key}>
      <label htmlFor={key} className="p-col-4 p-text-lowercase p-as-start p-p-1">
        <small>{label}</small>
      </label>
      <div className="p-col-8 p-px-1">{children}</div>
    </div>
  );
};
