import React from 'react';
import {OptionDefinition_v2} from 'two-core';
import {Dropdown} from 'primereact/dropdown';

interface Props {
  key: string;
  selectedOption?: OptionDefinition_v2;
  disabled: boolean;
  visibleOptions: OptionDefinition_v2[];
}

export const SelectField = ({key, selectedOption, disabled, visibleOptions}: Props) => {
  const itemTemplate = (option: OptionDefinition_v2) => {
    if (option?.label) {
      if (option.dataValue) {
        return `${option.label} - ${option.dataValue}`;
      }
      return option.label;
    }
    return 'empty';
  };

  return (
    <Dropdown
      name={key}
      id={key}
      value={selectedOption}
      options={visibleOptions}
      disabled={disabled}
      tooltip={selectedOption?.label}
      tooltipOptions={{
        showOnDisabled: true,
        showDelay: 400,
        position: 'top',
      }}
      itemTemplate={itemTemplate}
      valueTemplate={itemTemplate}
      filter={visibleOptions.length > 7}
      optionLabel="label"
    />
  );
};
