import {PanelHeaderTemplateOptions} from 'primereact/panel';
import {DocumentItem} from 'two-core';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {DocumentItemFormMode} from './DocumentItemForm';
import {InputText} from 'primereact/inputtext';

interface Props {
  options: PanelHeaderTemplateOptions;
  mode: DocumentItemFormMode;
  item: DocumentItem;
  onTogglerClick?: () => void;
  collapsed?: boolean;
  deleted?: boolean;
  showInvalidFields?: boolean;
}
export const DocumentItemPanelHeader = ({
  options,
  mode,
  item,
  onTogglerClick,
  collapsed,
  deleted,
  showInvalidFields,
}: Props) => {
  const className = `${options.className} justify-content-start`;
  const titleClassName = `${options.titleClassName} p-as-center`;
  // const collapsed = this.props.onItemToggle ? this.props.collapsed : this.state.collapsed;
  const invalidQuantityClass = showInvalidFields && !item.qty ? 'invalid' : '';
  // const unitSystem = this.usersService?.unitSystem;
  // const size = this.getSize();
  const title = deleted ? `Deleted item ${item.index}` : `Item ${item.index}`;

  let itemMenuModel;
  const actionsButton = <></>;
  // if (this.props.mode !== 'readonly') {
  //   if (this.props.isDeleted) {
  //     const onItemRestore = this.props.onItemRestore;
  //     if (onItemRestore) {
  //       itemMenuModel = [
  //         {
  //           label: 'Restore',
  //           command: () => onItemRestore(this.props.item),
  //         },
  //       ];
  //       actionsButton = <Button label="Restore" onClick={() => onItemRestore(this.props.item)} />;
  //     }
  //   } else {
  //     const splitButtonItems = [
  //       {
  //         label: 'Validate',
  //         command: this.onValidate,
  //       },
  //       {
  //         label: 'Delete',
  //         command: () => {
  //           this.props.onItemDeleted(this.props.item.index);
  //         },
  //       },
  //     ];
  //     itemMenuModel = [
  //       ...splitButtonItems,
  //       {
  //         label: 'Copy',
  //         command: this.onItemCopied,
  //       },
  //     ];
  //     actionsButton = (
  //       <SplitButton label="Copy" model={splitButtonItems} onClick={this.onItemCopied} menuButtonClassName="p-py-2" />
  //     );
  //   }
  // }
  return (
    <div className={className}>
      <div className={'p-d-flex p-jc-between w-100 order-item-layout'}>
        <div>
          <div className="p-field p-grid p-m-0 h-100">
            {mode !== 'readonly' && !deleted && (
              <button className={`${options.togglerClassName} p-as-center`} onClick={onTogglerClick}>
                <FontAwesomeIcon icon={collapsed ? faChevronRight : faChevronDown} />
              </button>
            )}
            <div className="p-col-fixed p-as-center p-px-0 p-py-1" style={{minWidth: '56px'}}>
              <span className={titleClassName}>{title}</span>
            </div>

            <div className="p-col-fixed p-d-flex p-pl-2 p-pr-0" style={{minWidth: '74px'}}>
              <label htmlFor="quantity" className={`p-mb-0 p-mr-1 p-py-1 p-as-center ${invalidQuantityClass}`}>
                <small>quantity</small>
              </label>
              <div className="p-as-center" style={{width: '40px'}}>
                <InputText
                  name="quantity"
                  id="quantity"
                  pattern="[0-9]*"
                  className="p-p-1 w-100"
                  value={item.qty}
                  // onChange={onQuantityChanged}
                  disabled={true}
                />
              </div>
            </div>
            {/*<div className="p-col-fixed p-d-flex p-pl-2 p-pr-0 p-py-1" style={{minWidth: '74px'}}>*/}
            {/*  {!!size && (*/}
            {/*    <label className={`p-m-0 p-as-center ${invalidQuantityClass}`}>*/}
            {/*      <small> {unitSystem === 'metric' ? `${size.toFixed(2)} sqm` : `${size.toFixed(3)} sqf`}</small>*/}
            {/*    </label>*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>
        </div>
        {/*{this.props.mode !== 'readonly' && (*/}
        {/*  <div className="p-d-flex">*/}
        {/*    <div className="p-as-center">*/}
        {/*      <div className="p-d-none p-d-lg-block">{actionsButton}</div>*/}
        {/*      <div className="p-d-block p-d-lg-none">*/}
        {/*        <Menu model={itemMenuModel} popup ref={this.menu} id={`item_popup_menu${this.props.item.index}`} />*/}
        {/*        <Button*/}
        {/*          icon="pi pi-bars"*/}
        {/*          onClick={event => this.menu.current?.toggle(event)}*/}
        {/*          aria-controls={`item_popup_menu${this.props.item.index}`}*/}
        {/*          aria-haspopup*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  );
};
