import React from 'react';
import {EntityDetailDivider, EntityDetailField} from 'two-app-ui';
import {B2bRequest} from 'two-core';
import formats from '../../config/formats';
import {DateTime} from 'luxon';
interface Props {
  b2bRequest: B2bRequest;
}
export const B2bRequestDetail = ({b2bRequest}: Props) => {
  return (
    <>
      <EntityDetailField label="integration">{b2bRequest.b2b_integration?.name}</EntityDetailField>
      <EntityDetailField label="recived">
        {b2bRequest.received_at ? DateTime.fromISO(b2bRequest.received_at?.toString()).toFormat(formats.dateTime) : ''}
      </EntityDetailField>
      <EntityDetailDivider />
      <EntityDetailField label="mapped">{b2bRequest.mapped}</EntityDetailField>
      <EntityDetailField label="order">{b2bRequest.order_id}</EntityDetailField>
      <EntityDetailField label="responded">
        {b2bRequest.responded_at
          ? DateTime.fromISO(b2bRequest.responded_at?.toString()).toFormat(formats.dateTime)
          : ''}
      </EntityDetailField>
    </>
  );
};
