import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import React from 'react';
import {AppContext, TwoDialog, TwoToast} from 'two-app-ui';
import {B2bApiToken, B2bEmailToken, B2bIntegration, B2bIntegrationApiSettings} from 'two-core';
import B2bIntegrationsService from '../../../services/B2bIntegrationsService';
import {InputSwitch} from 'primereact/inputswitch';
import {SettingsSection} from './SettingsSection';
import {TokenSection} from './TokenSection';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  b2bIntegration?: B2bIntegration;
}

interface State {
  saving?: boolean;
  b2bIntegrationPatch?: Partial<B2bIntegration>;
}
export default class EditB2bIntegrationDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  b2bIntegrationsService?: B2bIntegrationsService;

  twoToast?: TwoToast;
  constructor(props: Props) {
    super(props);

    this.onHide = this.onHide.bind(this);
    this.onSave = this.onSave.bind(this);

    this.state = {saving: false, b2bIntegrationPatch: {}};

    this.onTokenChange = this.onTokenChange.bind(this);
    this.onSettingChange = this.onSettingChange.bind(this);
  }

  componentDidMount() {
    this.b2bIntegrationsService = this.context.b2bIntegrationsService;
    this.twoToast = this.context.twoToast;
  }

  onHide() {
    this.setState({
      saving: false,
      b2bIntegrationPatch: {},
    });
    this.props.onHide();
  }

  onSave() {
    const {b2bIntegration} = this.props;
    const {b2bIntegrationPatch} = this.state;
    if (b2bIntegrationPatch && Object.keys(b2bIntegrationPatch).length) {
      if (!this.validate(b2bIntegrationPatch, b2bIntegration)) {
        return;
      }
      if (b2bIntegration?.id) {
        this.updateB2bIntegration(b2bIntegration.id, b2bIntegrationPatch);
      } else {
        this.createB2bIntegration(b2bIntegrationPatch);
      }
    } else {
      this.twoToast?.showInfo('No changes to save');
      this.onHide();
    }
  }

  async updateB2bIntegration(b2bIntegrationId: number, b2bIntegrationPatch: Partial<B2bIntegration>) {
    this.setState({saving: true});
    try {
      await this.b2bIntegrationsService?.updateB2bIntegration(b2bIntegrationId, b2bIntegrationPatch);
      this.twoToast?.showSuccess('B2b integration updated');
      this.onHide();
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error updating b2b integration');
      this.setState({saving: false});
    }
  }

  async createB2bIntegration(b2bIntegrationPatch: Partial<B2bIntegration>) {
    this.setState({saving: true});
    try {
      await this.b2bIntegrationsService?.createB2bIntegration(b2bIntegrationPatch);
      this.twoToast?.showSuccess('B2b integration created');
      this.onHide();
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error creating b2b integration');
      this.setState({saving: false});
    }
  }

  validate(b2bIntegrationPatch: Partial<B2bIntegration>, b2bIntegration?: B2bIntegration) {
    if (b2bIntegration) {
      if (b2bIntegrationPatch.name === '') {
        this.twoToast?.showError('Name is required');
        return false;
      }
      if (b2bIntegrationPatch.manager_key === '') {
        this.twoToast?.showError('Manager key is required');
        return false;
      }
    } else {
      if (!b2bIntegrationPatch.name) {
        this.twoToast?.showError('Name is required');
        return false;
      }
      if (!b2bIntegrationPatch.manager_key) {
        this.twoToast?.showError('Manager key is required');
        return false;
      }
      if (!b2bIntegrationPatch.token) {
        this.twoToast?.showError('Token is required');
        return false;
      }
      if (!b2bIntegrationPatch.settings) {
        this.twoToast?.showError('Settings is required');
        return false;
      }
    }
    return true;
  }

  onB2bIntegrationChange(b2bIntegrationPatch: Partial<B2bIntegration>) {
    this.setState(state => ({b2bIntegrationPatch: {...state.b2bIntegrationPatch, ...b2bIntegrationPatch}}));
  }

  onTokenChange(tokenPatch: Partial<B2bApiToken | B2bEmailToken>) {
    let updatedToken = {} as B2bApiToken | B2bEmailToken;
    if (tokenPatch.type) {
      updatedToken.type = tokenPatch.type;
    } else {
      const token =
        this.state.b2bIntegrationPatch?.token ??
        this.props.b2bIntegration?.token ??
        ({} as B2bApiToken | B2bEmailToken);
      updatedToken = {...token, ...tokenPatch} as B2bApiToken | B2bEmailToken;
    }
    this.onB2bIntegrationChange({token: updatedToken});
  }

  onSettingChange(settingsPatch: Partial<B2bIntegrationApiSettings>) {
    let updatedSettings = {} as B2bIntegrationApiSettings;
    if (settingsPatch.type) {
      updatedSettings.type = settingsPatch.type;
    } else {
      const settings =
        this.state.b2bIntegrationPatch?.settings ??
        this.props.b2bIntegration?.settings ??
        ({} as B2bApiToken | B2bEmailToken);
      updatedSettings = {...settings, ...settingsPatch} as B2bIntegrationApiSettings;
    }
    this.onB2bIntegrationChange({settings: updatedSettings});
  }

  render() {
    const {showDialog, b2bIntegration} = this.props;
    const {saving, b2bIntegrationPatch} = this.state;

    const saveButtonLabel = b2bIntegration?.id ? 'Save' : 'Create';
    const footer = (
      <div className={'p-d-flex p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={this.onHide} disabled={saving} />
        <Button label={saveButtonLabel} onClick={this.onSave} loading={saving} disabled={saving} />
      </div>
    );

    const header = b2bIntegration?.id ? 'Edit B2b Integration' : 'New B2b Integration';
    const content = (
      <div className="p-fluid w-100">
        {/*Main*/}
        <div className="p-field p-grid">
          <label className="p-col-2">name</label>
          <div className="p-col-10">
            <InputText
              value={b2bIntegrationPatch?.name !== undefined ? b2bIntegrationPatch.name : b2bIntegration?.name}
              onChange={e => this.onB2bIntegrationChange({name: e.target.value})}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-2">enabled</label>
          <div className="p-col-1">
            <InputSwitch
              checked={
                b2bIntegrationPatch?.enabled !== undefined ? b2bIntegrationPatch.enabled : b2bIntegration?.enabled
              }
              onChange={e => this.onB2bIntegrationChange({enabled: e.value})}
            />
          </div>
          <label className="p-col-2">manager</label>
          <div className="p-col-7">
            <InputText
              value={
                b2bIntegrationPatch?.manager_key !== undefined
                  ? b2bIntegrationPatch.manager_key
                  : b2bIntegration?.manager_key
              }
              onChange={e => this.onB2bIntegrationChange({manager_key: e.target.value})}
            />
          </div>
        </div>
        <TokenSection onTokenChange={this.onTokenChange} token={b2bIntegrationPatch?.token ?? b2bIntegration?.token} />
        <SettingsSection
          settings={b2bIntegrationPatch?.settings ?? b2bIntegration?.settings}
          onSettingChange={this.onSettingChange}
        />
      </div>
    );

    return (
      <>
        <TwoDialog
          onHide={this.onHide}
          header={header}
          showDialog={showDialog}
          style={{width: '75vw'}}
          breakpoints={{'768px': '80vw', '576px': '90vw'}}
          draggable={false}
          footer={footer}
        >
          {content}
        </TwoDialog>
      </>
    );
  }
}
