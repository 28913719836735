import React from 'react';
import DocumentItemForm from './DocumentItemForm';
import {DocumentItem} from 'two-core';
import {AppContext, TwoToast} from 'two-app-ui';

interface Props {
  mode: 'readonly';
  documentItems: DocumentItem[];
}

export class DocumentItemsForm extends React.Component<Props, {}> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
  }

  render() {
    const {mode, documentItems} = this.props;
    return (
      <div className="w-100 p-pt-2 p-px-2 order-items-form-panel">
        <div className="items">
          {mode === 'readonly' && (
            <>
              {documentItems?.map(item => {
                return <DocumentItemForm key={item.index} mode={'readonly'} item={item} />;
              })}
            </>
          )}
        </div>
      </div>
    );
  }
}
