import React from 'react';
import {OptionDefinition_v2} from 'two-core';
import {Checkbox} from 'primereact/checkbox';

interface Props {
  key: string;
  selectedOptions?: OptionDefinition_v2[];
  disabled: boolean;
  visibleOptions: OptionDefinition_v2[];
}

export const CheckboxField = ({key, selectedOptions, disabled, visibleOptions}: Props) => {
  const selectedOptionKeys = selectedOptions?.map(option => option.key) ?? [];
  return (
    <div key={key}>
      {visibleOptions.map(option => {
        return (
          <div className="p-field-checkbox" key={option.key}>
            <Checkbox
              inputId={option.key}
              value={option.key}
              name={option.label}
              id={option.key}
              checked={selectedOptionKeys.includes(option.key)}
              disabled={disabled}
            />
            <label htmlFor={option.key}>{option.label}</label>
          </div>
        );
      })}
    </div>
  );
};
